@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #111827;
  --secondary-color: #4B5563;
  --background-color: #ffffff;
  --text-color: #111827;
  --border-color: #E5E7EB;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'HelversRegular';
  src: url('./fonts/helversregular-ax9vr.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AnticlaireDisplaySSi';
  src: url('./fonts/AnticlaireDisplaySSi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Custom Product Page Styles */
.aspect-w-1 {
  position: relative;
  padding-bottom: 100%;
}

.aspect-h-1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.product-image {
  transition: transform 0.3s ease-in-out;
}

.product-image:hover {
  transform: scale(1.05);
}

.product-title {
  font-family: 'HelversRegular', sans-serif;
}

.request-button {
  transition: all 0.2s ease-in-out;
}

.request-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Sticky header backdrop blur */
.sticky-header {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
